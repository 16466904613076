import { type UserRole } from "@yotta-vision/core/models/user";
import { type GetSessionResult } from "@yotta-vision/functions/rest/session";
import * as Equivalence from "effect/Equivalence";
import { constFalse, constNull, pipe } from "effect/Function";
import * as Match from "effect/Match";
import * as Option from "effect/Option";
import * as React from "react";
import { useSession } from "~lib/future/contexts/session";

/**
 * Roles form a poset, or a set with a partial order equivalence relation.
 * There are n choose 2 interesting combinations, and the rest are trivial.
 */
const roleEquivalence = Equivalence.make<UserRole>((self, that) => {
  if (self === "user") {
    if (that === "org-admin" || that === "admin") {
      return false;
    }
  } else if (self === "org-admin") {
    if (that === "admin") {
      return false;
    }
  }
  return true;
});

const isUserRole = (role: UserRole) =>
  Match.type<GetSessionResult>().pipe(
    Match.when({ type: "user" }, (session) =>
      roleEquivalence(session.properties.role, role),
    ),
    Match.orElse(constFalse),
  );

export const isOrgAdmin = isUserRole("org-admin");

const isAdmin = isUserRole("admin");

export const WithRole = ({
  validRoles,
  children,
}: React.PropsWithChildren<{ validRoles: UserRole[] }>) => {
  const { session } = useSession();

  const component = React.useMemo(
    () =>
      pipe(
        Option.fromNullable(session),
        Option.flatMap(
          Option.liftPredicate(
            (session) =>
              session.type === "user" &&
              validRoles.includes(session.properties.role),
          ),
        ),
        Option.match({ onNone: constNull, onSome: () => <>{children}</> }),
      ),
    [children, session, validRoles],
  );

  return component;
};
